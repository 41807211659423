#long-bun-sku-select {
  padding: 10px;
  font-size: 20px !important;
}
.dialog-text-long-bun-sku {
  font-size: 25px !important;
  margin-right: 10px !important;
}
.dialog-text-quantity {
  font-size: 25px !important;
  margin: 0 10px 0 15px !important;
}
#long-bun-number-input {
  font-size: 25px !important;
}