.datePicker {
  border: 2px solid;
  border-radius: 3px;
  margin: 0 10px;
  font-size: 20px !important;
}
.dateHeader {
  font-size: 2rem !important;
}
.dateHeader-grid {
  margin-left: 50px;
}
.scheduleHeader {
  font-size: 45px;
}
.table-buttons {
  color: black !important;
  font-weight: 600 !important;
  border: 1px solid !important;
}
.remove-button {
  color: white !important;
  font-weight: 600 !important;
  border: 1px solid !important;
  width: 200px;
  background-color: black !important;
}
.T8-cut-header {
  font-size: 1.5rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
