#law-tag-viewer-form {
  display: flex;
}
.selection-grid {
  display: flex;
  flex-direction: column !important;
}
.selection-text {
  font-weight: bold !important;
  font-size: 22px !important;
  padding: 0;
  margin-bottom: 10px !important;
}
.law-tag-viewer-select {
  border: 1px solid black !important;
  width: 100%;
}
.law-tag-viewer-select:focus {
  border: 2px solid black !important;
}
.law-tag-viewer-select:before {
  border: 2px solid black !important;
}
.law-tag-viewer-select:after {
  border: 2px solid black !important;
}
.law-tag-viewer-grid {
  width: auto !important;
  margin-left: 50px;
}
.submit-button-grid {
  margin-left: 15px;
}
.law-tag-viewer-assembly-grid {
  margin-left: 15px;
}
.i-frame-grid {
  width: 90%;
  height: 800px;
  margin: 25px 0;
}