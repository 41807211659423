.chit-sticker-printer-grid {
  padding: 10px;
  margin-bottom: 25px;
  border-bottom: solid 5px black;
}
.printer-component-box {
  margin: 0 !important;
}
.heading { 
  margin: 10px 0 10px 0;
}
.test-print-button {
  color: black !important;
  font-weight: 600 !important;
  font-size: large !important;
  border: 1px solid black !important;
}
.test-print-button-box {
  margin: 0 !important;
}
.printer-selected-box {
  margin-top: 15px !important;
}