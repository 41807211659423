.MuiFilledInput-root,
.sku_textfield {
  background-color: transparent !important;
  border: 1px solid black !important;
  border-radius: 5px;
}
.MuiFilledInput-root:focus,
.sku_textfield:focus-within {
  background-color: yellow !important;
}
.select-box {
  width: 100%;
  color: black !important;
  font-weight: 600 !important;
  font-size: large !important;
  border: 1px solid black !important;
  border-radius: 5px;
  text-transform: capitalize;
}
