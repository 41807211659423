.foam-pour-processing-table {
  max-width: 1150px !important;
  margin-bottom: 30px;
}
.foam-processing-header {
  font-size: 45px;
}
.foam-processing-date-header {
  font-size: 2rem !important;
}
.foam-processing-date-header-grid {
  margin-left: 50px;
}
.foam-processing-date-picker {
  border: 2px solid;
  border-radius: 3px;
  margin: 0 10px;
  font-size: 20px !important;
}
.foam-processing-table-grid {
  margin-top: 30px;
  margin-left: 50px;
}
.foam-processing-table-buttons {
  color: black !important;
  font-weight: 600 !important;
  border: 1px solid !important;
}
#long-bun-length-input {
  font-size: 25px !important;
  width: 100px !important;
}
.foam-processing-update-button {
  color: black !important;
  font-weight: 600 !important;
  border: 1px solid !important;
  width: 145px;
}