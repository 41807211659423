.linear_progress {
  position: absolute;
  top: 50%;
  left: 35%;
  width: 50%;
}
#alert-dialog-printed-description {
  font-size: 20px;
  color: red;
  font-weight: 600 !important;
}
.select-printer-box {
  margin-bottom: 10px;
}