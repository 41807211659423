.LogoContainer {
  position: absolute;
  width: 7em;
  height: 1em;
  left: 2em;
  top: 2em;
}

.Logo {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.MenuLogo {
  top: 20em;
  width: 2em;
  height: auto;
}

.User {
  padding: 1em;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 15%;
  height: 20%;
  color: white;
  z-index: 10;
}

.userCard {
  display: flex;
}

.userCardLeft {
  float: left;
  width: 40%;
}

.userCardRight {
  float: right;
  width: 60%;
  padding-left: 1em;
}

h3 {
  padding-left: 0.3em;
}

p {
  padding-left: 0.3em;
}

.ProfilePicture {
  width: 100%;
  height: auto;
}
.numberCircle {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 8px;
  background: #fff;
  color: white;
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.css-13eg511-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  background-color: blueviolet !important;
  /* color: white; */
}
