@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
.button-box {
  display: flex;
  justify-content: space-between;
}
.print-law-tags-button-box {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  
}
.law-tag-print-button {
  margin: 0 10px !important;
  color: black !important;
  font-weight: 600 !important;
  border: 1px solid black !important; 
  /* font-size: large !important; */
}
.print-button {
  margin: 0 10px !important;
  color: black !important;
  font-weight: 600 !important;
  /* font-size: large !important; */
  border: 1px solid black !important; 
}
#alert-dialog-title {
  font-weight: 700 !important;
  font-size: 35px !important;
}
#alert-dialog-description {
  font-weight: 500 !important;
  color: black;
}
.alert-button-div {
  justify-content: flex-start !important;
  padding-left: 24px !important;
  padding-bottom: 40px !important;
}
.alert-button {
  background-color: #1f2c32 !important;
  border-radius: 5px;
  color: #fff !important;
  font-family: Montserrat;
  
}
.print-button-group {
  margin: 10px 0 10px 0;
}
.data-table {
  padding: 15px;
}
.tab-button {
  font-size: 18px;
  padding: 10px 60px;
  cursor: pointer;
  opacity: 0.6;
  color: black;
  font-weight: bold;
  border-radius: 5px;
  margin: 0 5px;
  border: 0;
  outline: 0;
}
.alert-message {
  font-size: x-large !important;
}

/* @media only screen and (max-width: 1800px) {
  .law-tag-print-button {
    font-size: medium !important;
  }
  .print-button {
    font-size: medium !important;
  }
  .tab-button {
    font-size: 20px;
  }
} */