.dialog-button {
  background-color: #1f2c32 !important;
  border-radius: 5px;
  color: #fff !important;
  font-family: Montserrat;
}
.error-button {
  background-color: red !important;
  border-radius: 5px;
  color: white !important;
  font-family: Montserrat;
}