.FormSubmit {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 25%;
  margin-right: 25%;
}
.login_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-image: linear-gradient(
    to right bottom,
    #2a4de2,
    #5a4de4,
    #7a4de4,
    #944ee4,
    #ab4ee4,
    #8e68f3,
    #6f7cfc,
    #4e8cff,
    #00a6ff,
    #00b9f9,
    #00c8e2,
    #12d4c8
  );
}
.login_textfiled {
  background-color: white;
  font-weight: bold !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: black !important;
  font-weight: 600 !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font-weight: bold !important;
  font-family: Montserrat !important;
  font-size: 25px !important;
}

@media only screen and (max-width: 1000px) {
  .login_textfiled {
    min-width: 250px !important;
  }
  .login-submit-button {
    min-width: 250px !important;
  }
  .username-grid {
    max-width: none !important;;
  }
  .password-grid {
    max-width: none !important;;
  }
}

@media only screen and (max-width: 750px) {
  .login-submit-button {
    min-width: 100% !important;
  }
}
