.schedule-header {
  font-size: 45px;
}
.dateHeader {
  /* margin: 0 5px 0 25px !important; */
  font-size: 2rem !important;
}
.dateHeader-grid {
  margin-left: 50px;
}
.datePicker {
  border: 2px solid;
  border-radius: 3px;
  margin: 0 10px;
  font-size: 20px !important;
}
.addBunLineButton {
  color: white !important;
  border: 1px solid black !important;
  background-color: black !important;
}
.table-grid {
  margin-top: 30px;
  margin-left: 50px;
}
.submit-btn-grid {
  margin-top: 30px;
  margin-left: 50px;
}
.foamScheduleSubmitButton {
  color: white !important;
  border: 1px solid black !important;
  background-color: black !important;
}
.table-buttons {
  color: black !important;
  font-weight: 600 !important;
  border: 1px solid !important;
}
