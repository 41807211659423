.chit-button-box {
  display: flex;
  justify-content: space-between;
}
.print-chit-sticker-button-box {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}
#alert-box {
  font-size: 25px;
}
.select-lamination-box {
  margin: 10px 0;
}