.HeaderPP {
  position: fixed;
  background: white;
  color: white;
  top: 0em;
  width: 100%;
  height: 5em;
  padding-top: 0.5em;
  border-bottom: 0.7em solid transparent;
  border-image: linear-gradient(90deg, #e301c7 0.31%, #6b01fc 98.65%);
  border-image-slice: 1;
  padding-bottom: 0.5em;
  overflow: auto;
}

.HeaderPR {
  position: fixed;
  background: white;
  color: white;
  top: 0em;
  width: 100%;
  height: 5em;
  padding-top: 0.5em;
  border-bottom: 0.7em solid transparent;
  border-image: linear-gradient(90deg, #670afc 0.31%, #1485ff 98.65%);
  border-image-slice: 1;
  padding-bottom: 0.5em;
  overflow: auto;
}

.HeaderLR {
  position: fixed;
  background: white;
  color: white;
  top: 0em;
  width: 100%;
  height: 5em;
  padding-top: 0.5em;
  border-bottom: 0.7em solid transparent;
  border-image: linear-gradient(90deg, #138cff 0.31%, #04d0ff 98.65%);
  border-image-slice: 1;
  padding-bottom: 0.5em;
  overflow: auto;
}

.HeaderQA {
  position: fixed;
  background: white;
  color: white;
  top: 0em;
  width: 100%;
  height: 5em;
  padding-top: 0.5em;
  border-bottom: 0.7em solid transparent;
  border-image: linear-gradient(90deg, #03d4fe 0.31%, #01f9bf 98.65%);
  border-image-slice: 1;
  padding-bottom: 0.5em;
  overflow: auto;
}

.Left {
  float: left;
  width: 20%;
  text-align: left;
  overflow: auto;
}
.Center {
  display: inline-block;
  margin-left: 5px;
  /* width: 25%; */
  text-align: center;
  /* overflow: auto; */
}
.Right {
  float: right;
  width: 70%;
  text-align: right;
}

.Searchbar {
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0.4) 0%,
    rgba(217, 217, 217, 0) 176.56%
  );
  width: 20%;
  right: 1em;
}
.NavIcon {
  cursor: pointer;
}

@media only screen and (max-width: 1230px) {
  .Center {
    width: 50%;
  }
  .Left {
    width: 25%;
  }
}
@media only screen and (max-width: 950px) {
  .Center {
    width: 70%;
  }
  .Left {
    width: 25%;
  }
}
@media only screen and (max-width: 780px) {
  .Center {
    display: flex;
  }
  .Left {
    width: 25%;
  }
}
