.shippingAddButton {
  font-size: 1.25em;
  font-weight: 700;
  color: #fff;
  background: black !important;
  display: inline-block;
  margin-bottom: 30px;
  padding: 15px 30px;
  cursor: pointer;
}
.shippingAddButton:hover {
  background: #e301c7;
}

.skuAndOrderNumDiv {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 5%;
}

/* .closeButton {
  background-color: white;
  position: fixed;
  right: 30%;
  cursor: pointer;
} */

.submitButtonDiv {
  /* border: 2px dotted black; */
  text-align: center;
  margin-top: 5rem;
}
