.chit-station-grid {
  padding: 10px 0;
  margin-bottom: 25px;
  /* border-top: solid 5px #2894c3; */
  border-bottom: solid 5px black;
  display: flex !important;
  /* justify-content: space-evenly; */
}
.selection-box {
  display: flex !important;
}
.selected-grid {
  margin-left: 25px !important;
  display: flex;
}
.selected-box {
  display: flex !important;
}
.chit-station {
  margin: 10px 5px 10px 0;
  padding-right: 0 10px !important;

}
.chit-station-selection {
  margin: 10px 5px 10px 0;
  padding-right: 0 10px !important;
  font-weight: 400;
}
.station-selected-text {
  margin: 0 !important;
}