.modalTextView {
  padding-left: 1em;
}
.shippingAddButton {
  font-size: 1.25em;
  font-weight: 700;
  color: #fff;
  background: linear-gradient(180deg, #7416f4 4%, #1976d2 130%);
  display: inline-block;
  margin-bottom: 30px;
  padding: 15px 30px;
  cursor: pointer;
}
