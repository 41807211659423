.css-1wc848c-MuiFormHelperText-root {
  color: red !important;
}
.serial-number-input-grid {
  display: flex;
}
.serial-number-form {
  display: flex;
}
#serial-number-textfield {
  height: 40px;
  padding: 5px 15px;
}
.form-box {
  width: 400px;
}
#law-tag-submit-btn {
  color: white !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  border: 1px solid black !important; 
  margin: 15px 10px 20px 0 !important;
  background-color: black;
}
.build-info-text {
  font-weight: 600 !important;
}
.build-info-header-text {
  font-weight: 600 !important;
  font-size: 18px !important;
}
.component-bom-info-grid {
  width: 300px;
}
.sku-info {
  /* text-align: center; */
  margin-left: 20px;
}
.sku-bom-info {
  display: flex;
}
.component-bom-info {
  display: flex;
}
.build-info-grid {
  width: 300px;
}
#sku-title {
  font-size: 50px;
  font-weight: 600;
}
#sku-sku {
  font-size: 20px;
  /* font-weight: 600; */
}
#sku-sn {
  font-size: 20px;
  font-weight: 500;
}
.tss-1akey0g-MUIDataTableHeadCell-data{
  text-transform: uppercase;
  font-weight: 600;
}
.linear_progress_bar_box {
  width: 100% !important;
}
.linear_progress_law_tag_viewer {
  background-color: rgb(116, 22, 244) !important;
  width: 90%;
  margin: auto;
  margin-top: 40px;
}
.mui-table {
  margin-bottom: 20px !important;
}
/* .MuiLinearProgress-colorPrimary {
  background-color: rgb(116, 22, 244);
} */
