.selection-grid {
  display: flex;
  flex-direction: column !important;
}
.selection-text {
  font-weight: bold !important;
  font-size: 22px !important;
  padding: 0;
  margin-bottom: 10px !important;
}
.work-order-select {
  border: 1px solid black !important;
  width: 100%;
  margin-bottom: 15px;
}
.work-order-select:focus {
  border: 2px solid black !important;
}
.work-order-select:before {
  border: 2px solid black !important;
}
.work-order-select:after {
  border: 2px solid black !important;
}
.work-order-textField {
  width: 250px;
}
.submit-button {
  color: white !important;
  border: 1px solid black !important;
  background-color: black !important;
}
.submit-button-grid {
  margin-top: 50px;
}
.form-grid {
  margin: 15px 20px 0 20px;
  width: auto !important;
  margin-left: 50px;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: black !important;
  font-weight: 600 !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline:focus {
  border-color: black !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}