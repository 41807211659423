.modal-field {
  margin-left: 1em;
}

.pfpRow {
  display: flex;
  flex-direction: row;
  margin-bottom: -2em;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  left: 0;
}

.ReactModal__Content {
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
  position: relative;
  inset: 15% auto auto 35% !important;
}

.profilepic {
  position: relative;
  width: 125px;
  height: 125px;
  left: 3em;
  bottom: 3em;
  border-radius: 100%;
  overflow: hidden;
  background-color: #111;
}

.profilepic:hover .profilepic__content {
  opacity: 1;
}

.profilepic:hover .profilepic__image {
  opacity: 0.5;
}

.profilepic__image {
  width: 125px;
  height: 125px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.profilepic__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.profilepic__icon {
  color: white;
  padding-bottom: 8px;
}

.profilepic__text {
  text-transform: uppercase;
  font-size: 12px;
  width: 50%;
  text-align: center;
}
.user-button {
  margin: 0 10px !important;
  color: black !important;
  font-weight: 600 !important;
  font-size: large !important;
  border: 1px solid black !important;
}

.create-new-user-button {
  margin: 0 0 1em 24px;
  background-color: black !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: large !important;
  border: 1px solid black !important;
}

.edit-button {
  background-color: black !important;
  color: white !important;
  font-weight: bold !important;
  font-size: small !important;
  border: 1px solid black !important;
}

.data-table {
  padding: 15px;
}

.error-message {
  color: red;
  font-weight: 600 !important;
  margin: 10px 0 10px 0 !important;
  font-size: 25px !important;
}
.title {
  font-family: Montserrat !important;
  font-weight: 700 !important;
}
