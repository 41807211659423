h1 {
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 30px;
}

.navItem {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

body {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

/*necessary container for both bars to not affect the main content*/
.Container {
  margin-top: 119px;
  /* margin-left: 20%; */
}

/* Two different bodies. One for left-aligned, one for center aligned */
.Body {
  padding-left: 30px;
}

.BodyCentered {
  text-align: center;
}

/* Nav Icon Container  */
.NavIcon {
  max-width: 20%;
  max-height: 5em;
  padding-left: 1em;
}

.Icon {
  width: 10em;
  height: auto;
  padding-left: 1em;
}
