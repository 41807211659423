.law-tag-printer-box {
margin: 0 15px 10px 15px;
}
.law-tag-printer-grid {
  padding: 20px;
  margin-bottom: 25px;
  border-bottom: solid 5px black;
}
.law-tag-select {
  width: 230px;
}