body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace, Montserrat;
}

@font-face {
  font-family: 'Montserrat';
  font-style: NORMAL;
  src: url(./components/global/fonts/Montserrat.ttf) format('tff');
}

.main_body {
  flex-grow: 1;
  /* padding: 1px; */
  transition: margin var(--transition-easing, ease)
    var(--transition-duration, 0.3s);

  margin-left: 0;
  /* Replace 'drawerWidth' with the actual value */
}

.main_body.open {
  transition: margin var(--transition-easing, ease)
    var(--transition-duration, 0.3s);
  margin-left: calc(-1 * var(--drawer-width, -20vw));
}
