.data-table {
  padding: 15px;
  /* width: 80%; */
}
.spacer-box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.assign-button-box {
  display: flex;
  justify-content: flex-end;
}